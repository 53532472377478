import React, { useState, useEffect, useRef } from 'react';
import { Mic, MicOff, AlertCircle } from 'lucide-react';
import { Button } from './ui/button';
import { Card, CardContent, CardHeader, CardTitle } from './ui/card';
import { Alert, AlertDescription } from './ui/alert';

const StreamingTranscription = ({ state, setState }) => {
  const [isRecording, setIsRecording] = useState(state.isRecording || false);
  const [finalTranscription, setFinalTranscription] = useState(state.finalTranscription || '');
  const [partialTranscription, setPartialTranscription] = useState(state.partialTranscription || '');
  const [error, setError] = useState(state.error || null);
  const [connectionStatus, setConnectionStatus] = useState(state.connectionStatus || 'disconnected');
  const websocketRef = useRef(null);
  const audioContextRef = useRef(null);
  const streamRef = useRef(null);
  const processorRef = useRef(null);

  useEffect(() => {
    setState({ isRecording, finalTranscription, partialTranscription, error, connectionStatus });
  }, [isRecording, finalTranscription, partialTranscription, error, connectionStatus, setState]);

  const startRecording = async () => {
    try {
      console.log('Starting recording...');
      streamRef.current = await navigator.mediaDevices.getUserMedia({ 
        audio: { 
          sampleRate: 16000,
          channelCount: 1,
          echoCancellation: true,
          noiseSuppression: true
        } 
      });
      console.log('Media stream created');

      audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)({ sampleRate: 16000 });
      console.log('Audio context created');

      const source = audioContextRef.current.createMediaStreamSource(streamRef.current);
      console.log('Media stream source created');
      
      processorRef.current = audioContextRef.current.createScriptProcessor(4096, 1, 1);
      source.connect(processorRef.current);
      processorRef.current.connect(audioContextRef.current.destination);
      console.log('Audio processor created and connected');

      websocketRef.current = new WebSocket('wss://bit-streaming.1bitai.ru/ws');
      console.log('WebSocket connection initiated');
      
      websocketRef.current.onopen = () => {
        setConnectionStatus('connected');
        setError(null);
        console.log('WebSocket connection opened');
      };

      websocketRef.current.onmessage = (event) => {
        const data = JSON.parse(event.data);
        console.log('Received message:', data);
        
        if (data.type === 'partial') {
          setPartialTranscription(data.text);
        } else if (data.type === 'final') {
          setFinalTranscription(prev => prev + (prev ? ' ' : '') + data.text);
          setPartialTranscription('');
        }
      };

      websocketRef.current.onerror = (event) => {
        console.error('WebSocket error:', event);
        setError('Ошибка WebSocket соединения');
        setConnectionStatus('error');
      };

      websocketRef.current.onclose = () => {
        console.log('WebSocket connection closed');
        setConnectionStatus('disconnected');
      };

      processorRef.current.onaudioprocess = (e) => {
        if (websocketRef.current && websocketRef.current.readyState === WebSocket.OPEN) {
          const inputData = e.inputBuffer.getChannelData(0);
          const outputData = new Int16Array(inputData.length);
          for (let i = 0; i < inputData.length; i++) {
            const s = Math.max(-1, Math.min(1, inputData[i]));
            outputData[i] = s < 0 ? s * 0x8000 : s * 0x7FFF;
          }
          
          websocketRef.current.send(outputData.buffer);
        }
      };

      setIsRecording(true);
      console.log('Recording started');
    } catch (error) {
      console.error('Error accessing microphone:', error);
      setError('Ошибка доступа к микрофону');
    }
  };

  const stopRecording = () => {
    console.log('Stopping recording...');
    if (processorRef.current) {
      processorRef.current.disconnect();
      processorRef.current = null;
      console.log('Audio processor disconnected');
    }
    if (audioContextRef.current) {
      audioContextRef.current.close();
      audioContextRef.current = null;
      console.log('Audio context closed');
    }
    if (streamRef.current) {
      streamRef.current.getTracks().forEach(track => track.stop());
      streamRef.current = null;
      console.log('Media stream stopped');
    }
    if (websocketRef.current) {
      websocketRef.current.close();
      console.log('WebSocket connection closed');
    }
    setIsRecording(false);
    setConnectionStatus('disconnected');
    setPartialTranscription('');
    console.log('Recording stopped');
  };

  useEffect(() => {
    return () => {
      stopRecording();
    };
  }, []);

  return (
    <Card className="bg-white">
      <CardHeader>
        <CardTitle className="text-xl font-bold">Потоковое распознавание</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="mb-4">
          <Button
            onClick={isRecording ? stopRecording : startRecording}
            className={`w-full ${isRecording ? 'bg-red-500 hover:bg-red-600' : 'bg-[#111827] hover:bg-[#374151]'} text-white`}
          >
            {isRecording ? <MicOff className="w-4 h-4 mr-2" /> : <Mic className="w-4 h-4 mr-2" />}
            {isRecording ? 'Остановить запись' : 'Начать запись'}
          </Button>
        </div>
        {error && (
          <Alert variant="destructive" className="mb-4">
            <AlertCircle className="h-4 w-4" />
            <AlertDescription>{error}</AlertDescription>
          </Alert>
        )}
        <div className="mb-2">
          Статус соединения: {connectionStatus}
        </div>
        <div className="bg-gray-100 p-4 rounded-lg min-h-[200px] max-h-[400px] overflow-y-auto">
          <p>{finalTranscription}</p>
          <p className="text-gray-500">{partialTranscription}</p>
        </div>
      </CardContent>
    </Card>
  );
};

export default StreamingTranscription;
