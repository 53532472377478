import React, { useState } from 'react';
import { Card, CardHeader, CardTitle, CardContent } from './ui/card';
import { Input } from './ui/input';
import { Button } from './ui/button';
import { authService } from '../services/authService';

const AuthPage = ({ onAuth }) => {
  const [email, setEmail] = useState('');
  const [token, setToken] = useState('');
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');

  const handleRequestToken = async () => {
    setError('');
    setMessage('');
    try {
      const response = await authService.requestToken(email);
      setMessage(response.message);
    } catch (err) {
      setError(err.message);
    }
  };

  const handleSubmitToken = async () => {
    setError('');
    setMessage('');
    try {
      await onAuth(token);
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <Card className="w-full max-w-md">
        <CardHeader className="flex flex-row justify-between items-center pb-6">
          <CardTitle className="text-xl font-bold">Newton-I</CardTitle>
          <img src="/logo_firstbit.png" alt="firstBit Logo" className="h-8 w-auto" />
        </CardHeader>
        <CardContent className="space-y-6">
          <div>
            <Input
              type="email"
              placeholder="Введите ваш email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <Button 
            className="w-full bg-[#111827] hover:bg-[#374151] text-white" 
            onClick={handleRequestToken}
          >
            Запросить токен
          </Button>
          <div>
            <Input
              type="text"
              placeholder="Введите токен доступа"
              value={token}
              onChange={(e) => setToken(e.target.value)}
            />
          </div>
          <Button 
            className="w-full bg-[#111827] hover:bg-[#374151] text-white"
            onClick={handleSubmitToken}
          >
            Войти
          </Button>
          {error && <p className="text-center text-red-500">{error}</p>}
          {message && <p className="text-center text-green-500">{message}</p>}
        </CardContent>
      </Card>
    </div>
  );
};

export default AuthPage;
