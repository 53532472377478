import React, { useState, useEffect } from 'react';
import { Tabs, TabsList, TabsTrigger, TabsContent } from "./components/ui/tabs"
import { Button } from "./components/ui/button"
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "./components/ui/card"
import { LogOut, HelpCircle } from 'lucide-react'
import TranscriptionWithDiarization from './components/TranscriptionWithDiarization';
import TranscriptionWithoutDiarization from './components/TranscriptionWithoutDiarization';
import StreamingTranscription from './components/StreamingTranscription';
import MeetingProtocol from './components/MeetingProtocol';
import AuthPage from './components/AuthPage';
import { authService } from './services/authService';

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [tokenData, setTokenData] = useState(null);
  
  // Состояния для каждой вкладки
  const [transcriptionState, setTranscriptionState] = useState({});
  const [diarizationState, setDiarizationState] = useState({});
  const [streamingState, setStreamingState] = useState({});
  const [protocolState, setProtocolState] = useState({});
  const [activeTab, setActiveTab] = useState('transcription');

  useEffect(() => {
    const checkAuth = async () => {
      if (authService.isLoggedIn()) {
        try {
          const token = authService.getToken();
          const data = await authService.checkToken(token);
          setIsLoggedIn(true);
          setTokenData(data);
        } catch (error) {
          console.error('Error checking auth:', error);
          handleLogout();
        }
      }
    };
    checkAuth();
  }, []);


  const onOpenProtocol = (text) => {
    setProtocolState(prev => ({
      ...prev,
      transcriptionText: text
    }));

    setActiveTab('protocol');
  };

  const handleAuth = async (token) => {
    try {
      const data = await authService.checkToken(token);
      setIsLoggedIn(true);
      setTokenData(data);
    } catch (error) {
      console.error('Error during login:', error);
    }
  };

  const handleLogout = () => {
    authService.logout();
    setIsLoggedIn(false);
    setTokenData(null);
  };

  const formatToken = (token) => {
    if (token && token.length > 10) {
      return `${token.slice(0, 5)}...${token.slice(-5)}`;
    }
    return token;
  };

  const formatTimeLeft = (timeLeft) => {
    const match = timeLeft.match(/(\d+) days?/);
    if (match) {
      const days = parseInt(match[1]);
      return `осталось ${days} ${getDayWord(days)}`;
    }
    return 'время истекло';
  };

  const getDayWord = (number) => {
    if (number >= 11 && number <= 19) return 'дней';
    const lastDigit = number % 10;
    if (lastDigit === 1) return 'день';
    if (lastDigit >= 2 && lastDigit <= 4) return 'дня';
    return 'дней';
  };

  if (!isLoggedIn) {
    return <AuthPage onAuth={handleAuth} />;
  }

  return (
    <div className="min-h-screen bg-white p-6">
      <div className="max-w-7xl mx-auto bg-[#f3f4f6] rounded-lg p-6">

        <header className="flex items-center justify-between mb-6">
          <div className="flex items-center space-x-4">
            <img src="/logo_firstbit.png" alt="firstBit Logo" className="h-8 w-auto" />
            <h1 className="text-2xl font-bold">Newton</h1>
          </div>

<div className="flex items-center space-x-4">
  {tokenData && (
    <div className="flex items-center space-x-2">
      <span className="text-sm">{formatToken(authService.getToken())}</span>
      <span className="text-sm text-gray-500">
        ({formatTimeLeft(tokenData.time_left)})
      </span>
    </div>
  )}
  <Button 
    variant="outline" 
    size="sm"
    asChild
  >
    <a
      href="https://t.me/AILabAssistantBot"
      target="_blank"
      rel="noopener noreferrer"
      className="flex items-center"
    >
      <HelpCircle className="w-4 h-4 mr-2" />
      Поддержка
    </a>
  </Button>
  <Button onClick={handleLogout} variant="outline" size="sm">
    <LogOut className="w-4 h-4 mr-2" />
    Выйти
  </Button>
</div>
        </header>

        <Card className="mb-6">
          <CardHeader>
            <div className="flex items-center justify-between">
              <div>
                <CardTitle className="text-xl font-bold">Добро пожаловать</CardTitle>
                <CardDescription>Выберите нужный режим работы из вкладок ниже.</CardDescription>
              </div>
            </div>
          </CardHeader>
        </Card>

        <Tabs value={activeTab} onValueChange={setActiveTab}>
          <TabsList className="grid w-full grid-cols-4 mb-6">
            <TabsTrigger value="transcription" className="data-[state=active]:bg-white">
              Транскрипция
            </TabsTrigger>
            <TabsTrigger value="diarization">Диаризация (бета)</TabsTrigger>
            <TabsTrigger value="streaming">Потоковое</TabsTrigger>
            <TabsTrigger value="protocol">Протокол</TabsTrigger>
          </TabsList>
          <TabsContent value="transcription">
            <TranscriptionWithoutDiarization 
              state={transcriptionState}
              setState={setTranscriptionState}
              onOpenProtocol={onOpenProtocol}
            />
          </TabsContent>
          <TabsContent value="diarization">
            <TranscriptionWithDiarization 
              state={diarizationState}
              setState={setDiarizationState}
              onOpenProtocol={onOpenProtocol}
            />
          </TabsContent>
          <TabsContent value="streaming">
            <StreamingTranscription 
              state={streamingState}
              setState={setStreamingState}
            />
          </TabsContent>
          <TabsContent value="protocol">
            <MeetingProtocol 
              state={protocolState}
              setState={setProtocolState}
            />
          </TabsContent>
        </Tabs>
      </div>
    </div>
  );
};

export default App;
