import React, { useState, useEffect, lazy, Suspense  } from 'react';
import { Button } from "./components/ui/button"
import { LogOut, Menu, ChevronDown, ChevronUp, User, MessageCircle } from 'lucide-react'
import TranscriptionWithoutDiarization from './components/TranscriptionWithoutDiarization';
const StreamingTranscription = lazy(() => import('./components/StreamingTranscription'));
import MeetingProtocol from './components/MeetingProtocol';
import AuthPage from './components/AuthPage';
import { authService } from './services/authService';

const App = () => {
 const [isLoggedIn, setIsLoggedIn] = useState(false);
 const [tokenData, setTokenData] = useState(null);
 const [activeTab, setActiveTab] = useState('transcription');
 const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
 const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
 
 const [transcriptionState, setTranscriptionState] = useState({});
 const [streamingState, setStreamingState] = useState({});
 const [protocolState, setProtocolState] = useState({});

 useEffect(() => {
   const checkAuth = async () => {
     if (authService.isLoggedIn()) {
       try {
         const token = authService.getToken();
         const data = await authService.checkToken(token);
         setIsLoggedIn(true);
         setTokenData(data);
       } catch (error) {
         console.error('Error checking auth:', error);
         handleLogout();
       }
     }
   };
   checkAuth();
 }, []);

useEffect(() => {
  const handleClickOutside = (event) => {
    // Проверяем, был ли клик вне меню пользователя
    if (!event.target.closest('.user-menu') && isUserMenuOpen) {
      setIsUserMenuOpen(false);
    }
  };

  // Добавляем слушатель при монтировании
  document.addEventListener('mousedown', handleClickOutside);

  // Удаляем слушатель при размонтировании
  return () => {
    document.removeEventListener('mousedown', handleClickOutside);
  };
}, [isUserMenuOpen]); // Зависимость от состояния меню

 const onOpenProtocol = (text) => {
   setProtocolState(prev => ({
     ...prev,
     transcriptionText: text
   }));
   setActiveTab('protocol');
 };

const handleAuth = async (token) => {
  try {
    const data = await authService.checkToken(token);
    setIsLoggedIn(true);
    setTokenData(data);
    return data; // Возвращаем данные в случае успеха
  } catch (error) {
    console.error('Error during login:', error);
    // Пробрасываем ошибку обратно в AuthPage для отображения пользователю
    throw error;
  }
};

 const handleLogout = () => {
   authService.logout();
   setIsLoggedIn(false);
   setTokenData(null);
 };

 const formatTimeLeft = (timeLeft) => {
   const match = timeLeft.match(/(\d+) days?/);
   if (match) {
     const days = parseInt(match[1]);
     return `осталось ${days} ${getDayWord(days)}`;
   }
   return 'время истекло';
 };

 const getDayWord = (number) => {
   if (number >= 11 && number <= 19) return 'дней';
   const lastDigit = number % 10;
   if (lastDigit === 1) return 'день';
   if (lastDigit >= 2 && lastDigit <= 4) return 'дня';
   return 'дней';
 };

 if (!isLoggedIn) {
   return <AuthPage onAuth={handleAuth} />;
 }

 return (
   <div className="min-h-screen bg-white font-montserrat">
     <header className="border-b border-gray-200">
       <div className="w-full mx-auto px-8 lg:px-12">
         <div className="flex justify-between items-center h-16">
           <div className="flex items-center">
             <img src="/logo_firstbit_newton.png" alt="firstBit Logo" className="h-8 w-auto" />
           </div>

           <div className="lg:hidden">
             <Button 
               variant="ghost" 
               size="sm"
               onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
             >
               <Menu className="w-6 h-6" />
             </Button>
           </div>
           
           <nav className="hidden lg:flex space-x-12">
             <button 
               id="nav-transcription"
               className={`px-3 py-2 text-base font-medium ${activeTab === 'transcription' ? 'text-violet-600' : 'text-gray-500 hover:text-gray-700'}`}
               onClick={() => setActiveTab('transcription')}
             >
               <span>Транскрипция</span>
             </button>
             <button
               id="nav-streaming"
               className={`px-3 py-2 text-base font-medium ${activeTab === 'streaming' ? 'text-violet-600' : 'text-gray-500 hover:text-gray-700'}`}
               onClick={() => setActiveTab('streaming')}
             >
               <span>Потоковое распознавание</span>
             </button>
             <button
               id="nav-protocol"
               className={`px-3 py-2 text-base font-medium ${activeTab === 'protocol' ? 'text-violet-600' : 'text-gray-500 hover:text-gray-700'}`}
               onClick={() => setActiveTab('protocol')}
             >
               <span>Протокол</span>
             </button>
             <button
               id="nav-history"
               className={`px-3 py-2 text-base font-medium ${activeTab === 'history' ? 'text-violet-600' : 'text-gray-500 hover:text-gray-700'}`}
               onClick={() => setActiveTab('history')}
             >
               <span>История</span>
             </button>
           </nav>

<div className="hidden lg:flex items-center relative user-menu">
             {tokenData && (
               <div className="relative">
                 <button
                   className="flex items-center px-3 py-2 text-sm hover:bg-gray-50 rounded-md"
                   onClick={() => setIsUserMenuOpen(!isUserMenuOpen)}
                 >
                   <span id="user-email">{tokenData.email}</span>
                   {isUserMenuOpen ? <ChevronUp className="w-4 h-4 ml-2" /> : <ChevronDown className="w-4 h-4 ml-2" />}
                 </button>

                 {isUserMenuOpen && (
                   <div className="absolute right-0 top-full mt-1 w-56 bg-white rounded-md shadow-lg border border-gray-200 py-1 z-10">
                     <button id="profile-button" className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-50" onClick={() => setIsUserMenuOpen(false)}>
                       <User className="w-4 h-4 mr-2" />
                       <span>Личный кабинет</span>
                     </button>
                     <a id="support-link" href="https://t.me/AILabAssistantBot" target="_blank" rel="noopener noreferrer" className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-50" onClick={() => setIsUserMenuOpen(false)}>
                       <MessageCircle className="w-4 h-4 mr-2" />
                       <span>Поддержка</span>
                     </a>
                     <button id="logout-button" className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-50" onClick={() => {handleLogout(); setIsUserMenuOpen(false);}}>
                       <LogOut className="w-4 h-4 mr-2" />
                       <span>Выйти</span>
                     </button>
                   </div>
                 )}
               </div>
             )}
           </div>
         </div>

         <div className={`lg:hidden ${isMobileMenuOpen ? 'block' : 'hidden'}`}>
           <div className="py-2 space-y-1">
             <button
               id="mobile-nav-transcription"
               className={`block w-full text-left px-3 py-2 text-sm font-medium ${activeTab === 'transcription' ? 'text-violet-600' : 'text-gray-500 hover:text-gray-700'}`}
               onClick={() => {setActiveTab('transcription'); setIsMobileMenuOpen(false);}}
             >
               <span>Транскрипция</span>
             </button>
             <button
               id="mobile-nav-streaming"
               className={`block w-full text-left px-3 py-2 text-sm font-medium ${activeTab === 'streaming' ? 'text-violet-600' : 'text-gray-500 hover:text-gray-700'}`}
               onClick={() => {setActiveTab('streaming'); setIsMobileMenuOpen(false);}}
             >
               <span>Потоковое распознавание</span>
             </button>
             <button
               id="mobile-nav-protocol"
               className={`block w-full text-left px-3 py-2 text-sm font-medium ${activeTab === 'protocol' ? 'text-violet-600' : 'text-gray-500 hover:text-gray-700'}`}
               onClick={() => {setActiveTab('protocol'); setIsMobileMenuOpen(false);}}
             >
               <span>Протокол</span>
             </button>
             <button
               id="mobile-nav-history"
               className={`block w-full text-left px-3 py-2 text-sm font-medium ${activeTab === 'history' ? 'text-violet-600' : 'text-gray-500 hover:text-gray-700'}`}
               onClick={() => {setActiveTab('history'); setIsMobileMenuOpen(false);}}
             >
               <span>История</span>
             </button>

             <div className="border-t border-gray-200 pt-4 mt-4 px-3 space-y-2">
               {tokenData && (
                 <>
                   <span id="mobile-user-email" className="block text-sm">{tokenData.email}</span>
                   <button id="mobile-profile-button" className="flex items-center w-full px-3 py-2 text-sm text-gray-700 hover:bg-gray-50" onClick={() => setIsMobileMenuOpen(false)}>
                     <User className="w-4 h-4 mr-2" />
                     <span>Личный кабинет</span>
                   </button>
                   <a id="mobile-support-link" href="https://t.me/AILabAssistantBot" target="_blank" rel="noopener noreferrer" className="flex items-center w-full px-3 py-2 text-sm text-gray-700 hover:bg-gray-50" onClick={() => setIsMobileMenuOpen(false)}>
                     <MessageCircle className="w-4 h-4 mr-2" />
                     <span>Поддержка</span>
                   </a>
                   <button id="mobile-logout-button" className="flex items-center w-full px-3 py-2 text-sm text-gray-700 hover:bg-gray-50" onClick={() => {handleLogout(); setIsMobileMenuOpen(false);}}>
                     <LogOut className="w-4 h-4 mr-2" />
                     <span>Выйти</span>
                   </button>
                 </>
               )}
             </div>
           </div>
         </div>
       </div>
     </header>

     <main className="w-full mx-auto px-8 lg:px-12 py-8">
       {activeTab === 'transcription' && (
         <TranscriptionWithoutDiarization
           state={transcriptionState}
           setState={setTranscriptionState}
           onOpenProtocol={onOpenProtocol}
         />
       )}
    {activeTab === 'streaming' && (
      <Suspense fallback={<div className="text-center py-8">Загрузка компонента...</div>}>
        <StreamingTranscription
          state={streamingState}
          setState={setStreamingState}
          onOpenProtocol={onOpenProtocol}
        />
      </Suspense>
    )}
       {activeTab === 'protocol' && (
         <MeetingProtocol
           state={protocolState}
           setState={setProtocolState}
         />
       )}
     </main>
   </div>
 );
};

export default App;