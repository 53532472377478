import axios from 'axios';

const API_BASE_URL = 'https://bit-auth.1bitai.ru';

const api = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  }
});

export const authService = {
  async requestToken(email) {
    try {
      const response = await api.post('/request-token', { email });
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 403) {
        throw new Error('Домен электронной почты не разрешен.');
      }
      throw new Error('Ошибка при запросе токена. Попробуйте позже.');
    }
  },

  async checkToken(token) {
    try {
      const response = await api.post('/check-token', { token });
      this.saveTokenData(token, response.data);
      return response.data;
    } catch (error) {
      if (error.response) {
        if (error.response.status === 404) {
          throw new Error('Токен не найден.');
        } else if (error.response.status === 403) {
          throw new Error('Срок действия токена истек. Новый токен отправлен на ваш email.');
        }
      }
      throw new Error('Ошибка при проверке токена. Попробуйте позже.');
    }
  },

  saveTokenData(token, data) {
    localStorage.setItem('authToken', token);
    localStorage.setItem('tokenData', JSON.stringify(data));
  },

  getToken() {
    return localStorage.getItem('authToken');
  },

  getTokenData() {
    const data = localStorage.getItem('tokenData');
    return data ? JSON.parse(data) : null;
  },

  logout() {
    localStorage.removeItem('authToken');
    localStorage.removeItem('tokenData');
  },

  isLoggedIn() {
    return !!this.getToken();
  }
};
