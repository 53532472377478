import React, { useState } from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from "./ui/dialog"
import { Input } from "./ui/input"
import { Button } from "./ui/button"

const EditSpeakersModal = ({ isOpen, onClose, speakers, onSave }) => {
  const [editedSpeakers, setEditedSpeakers] = useState(
    speakers.reduce((acc, участник) => ({ ...acc, [участник]: участник }), {})
  );

  const handleУчастникChange = (originalName, newName) => {
    setEditedSpeakers(prev => ({ ...prev, [originalName]: newName }));
  };

  const handleSave = () => {
    onSave(editedSpeakers);
    onClose();
  };

  // Сортировка участников по номеру (от 1 к большему)
  const sortedУчастники = [...speakers].sort((a, b) => {
    // Защита от ошибок, если в имени нет числа
    const aMatch = a.match(/\d+/);
    const bMatch = b.match(/\d+/);

    // Если в обоих именах есть числа, сортируем по ним
    if (aMatch && bMatch) {
      const aNum = parseInt(aMatch[0]);
      const bNum = parseInt(bMatch[0]);
      return aNum - bNum;
    }

    // Если только в одном имени есть число, ставим его первым
    if (aMatch) return -1;
    if (bMatch) return 1;

    // Если в обоих именах нет чисел, сортируем по алфавиту
    return a.localeCompare(b);
  });

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Редактировать имена участников</DialogTitle>
        </DialogHeader>
        {sortedУчастники.map(участник => (
          <div key={участник} className="mb-4">
            <label htmlFor={`участник-${участник}`} className="block text-sm font-medium text-gray-700 mb-1">
              {участник}
            </label>
            <Input
              id={`участник-${участник}`}
              type="text"
              value={editedSpeakers[участник]}
              onChange={(e) => handleУчастникChange(участник, e.target.value)}
            />
          </div>
        ))}
        <DialogFooter>
          <Button id="button-speakers-save" onClick={handleSave} className="bg-violet-600 hover:bg-violet-700 text-white">Сохранить</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default EditSpeakersModal;