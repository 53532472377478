import React, { useState } from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from "./ui/dialog"
import { Input } from "./ui/input"
import { Button } from "./ui/button"

const EditSpeakersModal = ({ isOpen, onClose, speakers, onSave }) => {
  const [editedSpeakers, setEditedSpeakers] = useState(
    speakers.reduce((acc, speaker) => ({ ...acc, [speaker]: speaker }), {})
  );

  const handleSpeakerChange = (originalName, newName) => {
    setEditedSpeakers(prev => ({ ...prev, [originalName]: newName }));
  };

  const handleSave = () => {
    onSave(editedSpeakers);
    onClose();
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Редактировать имена участников</DialogTitle>
        </DialogHeader>
        {speakers.map(speaker => (
          <div key={speaker} className="mb-4">
            <label htmlFor={`speaker-${speaker}`} className="block text-sm font-medium text-gray-700 mb-1">
              {speaker}
            </label>
            <Input
              id={`speaker-${speaker}`}
              type="text"
              value={editedSpeakers[speaker]}
              onChange={(e) => handleSpeakerChange(speaker, e.target.value)}
            />
          </div>
        ))}
        <DialogFooter>
          <Button onClick={handleSave}>Сохранить</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default EditSpeakersModal;
