import React, { useState, useRef, useEffect } from 'react';
import { Upload, Copy, Save, Trash2, Edit2 } from 'lucide-react';
import axios from 'axios';
import EditSpeakersModal from './EditSpeakersModal';
import { Button } from './ui/button';
import { Progress } from './ui/progress';
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from './ui/card';
import { authService } from '../services/authService';

const API_BASE_URL = 'https://devtools.1bitai.ru:9040';

const TranscriptionWithDiarization = ({ state, setState, onOpenProtocol }) => {
  const [file, setFile] = useState(state.file || null);
  const [transcription, setTranscription] = useState(state.transcription || null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [progress, setProgress] = useState(0);
  const [jobId, setJobId] = useState(state.jobId || null);
  const [error, setError] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const fileInputRef = useRef(null);

  useEffect(() => {
    setState({ file, transcription, isProcessing, progress, jobId, error });
  }, [file, transcription, isProcessing, progress, jobId, error, setState]);

  const cleanTimecodes = (text) => {
    if (!text) return '';
    // Сначала очищаем форматированный текст от таймкодов диаризации
    return text.replace(/\[\d+\.\d+s - \d+\.\d+s\]/g, '');
  };

  const handleOpenProtocol = () => {

    if (transcription && transcription.sentences) {
      // Собираем текст со спикерами, но без таймкодов
      const textWithSpeakers = transcription.sentences
        .map(sentence => `${sentence.speaker}: ${sentence.text}`)
        .join('\n');
      
      onOpenProtocol(textWithSpeakers);
    }

  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const droppedFile = event.dataTransfer.files[0];
    setFile(droppedFile);
  };

  const handleUpload = async () => {
    if (!file) {
      fileInputRef.current.click();
      return;
    }
    setIsProcessing(true);
    setProgress(0);
    setError(null);
    setTranscription(null);

    const formData = new FormData();
    formData.append('file', file);

    try {
      const token = authService.getToken();
      const response = await axios.post(`${API_BASE_URL}/transcribe?token=${token}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      setJobId(response.data.job_id);
    } catch (err) {
      setError('Ошибка загрузки файла: ' + (err.response?.data?.detail || err.message));
      setIsProcessing(false);
    }
  };

  useEffect(() => {
    let intervalId;
    if (jobId) {
      intervalId = setInterval(async () => {
        try {
          const token = authService.getToken();
          const response = await axios.get(`${API_BASE_URL}/result/${jobId}?token=${token}`);
          
          if (response.data.status === 'completed') {
            if (typeof response.data.result === 'string' && response.data.result.startsWith('Error:')) {
              setError('Ошибка обработки: ' + response.data.result);
              setIsProcessing(false);
            } else if (response.data.result && typeof response.data.result === 'object') {
              setTranscription(response.data.result);
              setIsProcessing(false);
              setProgress(100);
            } else {
              setError('Получен неожиданный формат ответа от сервера');
              setIsProcessing(false);
            }
            clearInterval(intervalId);
          } else if (response.data.status === 'processing') {
            setProgress((prevProgress) => Math.min(prevProgress + 10, 90));
          } else {
            setError('Получен неизвестный статус от сервера');
            setIsProcessing(false);
            clearInterval(intervalId);
          }
        } catch (err) {
          if (err.response && err.response.data && err.response.data.detail) {
            setError('Ошибка: ' + err.response.data.detail);
          } else {
            setError('Ошибка получения результатов: ' + err.message);
          }
          setIsProcessing(false);
          clearInterval(intervalId);
        }
      }, 5000);
    }
    return () => clearInterval(intervalId);
  }, [jobId]);

  const handleCopy = () => {
    if (transcription && transcription.formatted_transcript) {
      navigator.clipboard.writeText(transcription.formatted_transcript);
    }
  };

  const handleSave = () => {
    if (transcription) {
      const element = document.createElement("a");
      const file = new Blob([JSON.stringify(transcription, null, 2)], {type: 'application/json'});
      element.href = URL.createObjectURL(file);
      element.download = "transcription.json";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    }
  };

  const handleDelete = () => {
    setTranscription(null);
    setFile(null);
    setJobId(null);
    setProgress(0);
    setError(null);
  };

  const handleEditSpeakerNames = () => {
    setIsEditModalOpen(true);
  };

  const handleSaveSpeakerNames = (editedSpeakers) => {
    if (transcription) {
      const updatedSentences = transcription.sentences.map(sentence => ({
        ...sentence,
        speaker: editedSpeakers[sentence.speaker] || sentence.speaker
      }));

      const updatedTranscription = {
        ...transcription,
        sentences: updatedSentences
      };

      setTranscription(updatedTranscription);
    }
  };

  return (
    <Card className="bg-white">
      <CardHeader>
        <CardTitle className="text-xl font-bold">Диаризация (бета)</CardTitle>
        <p className="text-gray-600">Загрузите файл для транскрипции с распознаванием говорящих</p>
      </CardHeader>
      <CardContent>
        <div 
          className="border-2 border-dashed border-gray-300 rounded-lg p-12 mb-6 cursor-pointer bg-[#e5e7eb]"
          onDragOver={handleDragOver}
          onDrop={handleDrop}
          onClick={() => fileInputRef.current.click()}
        >
          <div className="flex flex-col items-center">
            <Upload className="w-12 h-12 text-gray-400 mb-2" />
            <p className="text-center text-gray-500">
              {file ? file.name : "Перетащите файл сюда или кликните для выбора"}
            </p>
            <input
              type="file"
              onChange={handleFileChange}
              className="hidden"
              ref={fileInputRef}
              accept=".mp3,.wav,.ogg,.flac"
            />
          </div>
        </div>

        <Button onClick={handleUpload} className="w-full bg-[#111827] hover:bg-[#374151] text-white" disabled={isProcessing}>
          <Upload className="w-4 h-4 mr-2" />
          {isProcessing ? 'Обработка...' : 'Загрузить и начать транскрипцию'}
        </Button>

        {isProcessing && (
          <div className="mt-6">
            <Progress value={progress} className="w-full" />
            <p className="text-center mt-2">Обработка файла: {progress}%</p>
          </div>
        )}

        {error && (
          <div className="mt-6 text-center text-red-500">
            <p>{error}</p>
          </div>
        )}

        {transcription && (
          <div className="mt-6">
            {transcription.formatted_transcript && (
              <div className="bg-gray-100 p-4 rounded-lg mb-4">
                <h3 className="font-bold mb-2">Полная транскрипция:</h3>
                <p>{transcription.formatted_transcript}</p>
              </div>
            )}
            
            <div className="bg-gray-100 p-4 rounded-lg">
              <h3 className="font-bold mb-2">Транскрипция по спикерам:</h3>
              {transcription.sentences.map((sentence, index) => (
                <div key={index} className="mb-2">
                  <span className="font-bold">{sentence.speaker}</span>
                  <span className="text-gray-500"> [{sentence.start_time.toFixed(2)}s - {sentence.end_time.toFixed(2)}s]</span>
                  <p>{sentence.text}</p>
                </div>
              ))}
            </div>
          </div>
        )}
      </CardContent>
      {transcription && (
        <CardFooter className="flex justify-between">
          <Button onClick={handleEditSpeakerNames} variant="outline">
            <Edit2 className="w-4 h-4 mr-2" />
            Редактировать имена участников
          </Button>
          <Button onClick={handleCopy} variant="outline">
            <Copy className="w-4 h-4 mr-2" />
            Копировать
          </Button>
          <Button onClick={handleSave} variant="outline">
            <Save className="w-4 h-4 mr-2" />
            Сохранить
          </Button>
          <Button onClick={handleDelete} variant="destructive">
            <Trash2 className="w-4 h-4 mr-2" />
            Удалить
          </Button>
          <Button 
            onClick={handleOpenProtocol}
            className="bg-[#111827] hover:bg-[#374151] text-white"
          >
            Протокол
          </Button>
        </CardFooter>
      )}

      <EditSpeakersModal
        isOpen={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        speakers={transcription ? [...new Set(transcription.sentences.map(s => s.speaker))] : []}
        onSave={handleSaveSpeakerNames}
      />
    </Card>
  );
};

export default TranscriptionWithDiarization;
