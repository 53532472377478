import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button } from './ui/button';
import { Progress } from './ui/progress';
import { Card, CardContent, CardHeader, CardTitle } from './ui/card';
import { Select } from './ui/select';
import { Textarea } from './ui/Textarea';
import { authService } from '../services/authService';
import { Copy, Bot, Settings, MessageSquare, HelpCircle } from 'lucide-react';

const API_BASE_URL = process.env.REACT_APP_SUMMARIZE_API_URL;

const MeetingProtocol = ({ state, setState }) => {
  const [transcriptionText, setTranscriptionText] = useState(state.transcriptionText || '');
  const [userPrompt, setUserPrompt] = useState(state.userPrompt || '');
  const [systemPrompt, setSystemPrompt] = useState(state.systemPrompt || '');
  const [selectedPromptId, setSelectedPromptId] = useState(state.selectedPromptId || 'meeting_detailed');
  const [resultText, setResultText] = useState(state.resultText || '');
  const [isProcessing, setIsProcessing] = useState(state.isProcessing || false);
  const [progress, setProgress] = useState(state.progress || 0);
  const [taskId, setTaskId] = useState(state.taskId || null);
  const [error, setError] = useState(state.error || null);
  const [predefinedPrompts, setPredefinedPrompts] = useState({});
  const [selectedModel, setSelectedModel] = useState(state.selectedModel || 'gpt4');
  const [taskStatus, setTaskStatus] = useState(state.taskStatus || null);

  useEffect(() => {
    fetchPredefinedPrompts();
  }, []);

  useEffect(() => {
    setState({
      transcriptionText,
      userPrompt,
      systemPrompt,
      selectedPromptId,
      resultText,
      isProcessing,
      progress,
      taskId,
      error,
      selectedModel,
      taskStatus
    });
  }, [transcriptionText, userPrompt, systemPrompt, selectedPromptId, resultText, isProcessing, 
      progress, taskId, error, selectedModel, taskStatus, setState]);

  const fetchPredefinedPrompts = async () => {
    try {
      const token = authService.getToken();
      const response = await axios.get(`${API_BASE_URL}/prompts`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      
      setPredefinedPrompts(response.data);
      
      // Устанавливаем значения для meeting_detailed по умолчанию
      if (response.data.meeting_detailed) {
        setSelectedPromptId('meeting_detailed');
        setSystemPrompt(response.data.meeting_detailed.system_prompt);
      }
    } catch (err) {
      console.error('Error fetching predefined prompts:', err);
      handleApiError(err, 'при загрузке промптов');
    }
  };

  const handlePromptSelect = (value) => {
    setSelectedPromptId(value);
    const selectedPrompt = predefinedPrompts[value];
    if (selectedPrompt) {
      setSystemPrompt(selectedPrompt.system_prompt);
    }
  };

  const startProcessing = async () => {
    if (!transcriptionText || !selectedPromptId) {
      setError('Необходимо заполнить текст и выбрать промпт');
      return;
    }

    setIsProcessing(true);
    setProgress(0);
    setError(null);
    setResultText('');
    setTaskId(null);
    setTaskStatus(null);

    try {
      const token = authService.getToken();
      const response = await axios.post(`${API_BASE_URL}/tasks`, {
        text: transcriptionText,
        prompt_id: selectedPromptId,
        model: selectedModel,
        user_prompt: userPrompt
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      setTaskId(response.data.task_id);
      await checkProgress(response.data.task_id);
    } catch (err) {
      console.error('Error starting processing:', err);
      handleApiError(err, 'при запуске обработки');
      setIsProcessing(false);
    }
  };

  const checkProgress = async (taskId) => {
    const pollInterval = setInterval(async () => {
      try {
        if (!taskId) {
          clearInterval(pollInterval);
          return;
        }

        const token = authService.getToken();
        const response = await axios.get(`${API_BASE_URL}/tasks/${taskId}/status`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        const { status, progress } = response.data;
        setTaskStatus(status);
        setProgress(progress);

        if (status === 'completed') {
          clearInterval(pollInterval);
          await fetchResult(taskId);
        } else if (status === 'failed') {
          clearInterval(pollInterval);
          setError('Произошла ошибка при обработке задачи');
          setIsProcessing(false);
        }
      } catch (err) {
        console.error('Error checking progress:', err);
        clearInterval(pollInterval);
        handleApiError(err, 'при проверке прогресса');
        setIsProcessing(false);
      }
    }, 5000);

    return () => clearInterval(pollInterval);
  };

  const fetchResult = async (taskId) => {
    try {
      const token = authService.getToken();
      const response = await axios.get(`${API_BASE_URL}/tasks/${taskId}/result`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      setResultText(response.data.summary);
      setProgress(100);
    } catch (err) {
      console.error('Error fetching result:', err);
      handleApiError(err, 'при получении результата');
    } finally {
      setIsProcessing(false);
    }
  };

  const handleApiError = (err, context) => {
    if (err.response?.status === 401) {
      setError('Ошибка авторизации ' + context);
    } else if (err.response?.status === 403) {
      setError('Токен истек');
    } else if (err.response?.status === 404) {
      setError('Задача или промпт не найдены');
    } else if (err.response?.status === 400) {
      setError('Неверные параметры запроса');
    } else if (err.response?.status === 500) {
      setError('Внутренняя ошибка сервера');
    } else {
      setError('Ошибка ' + context + ': ' + (err.response?.data?.message || err.message));
    }
  };

  return (
    <div id="protocol-page" className="space-y-6">
      <div className="grid grid-cols-1 lg:grid-cols-5 gap-6">
        {/* Левая колонка */}
        <div className="lg:col-span-3 space-y-6">
          <Card id="transcription-card">
            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-4">
              <div className="flex items-center gap-2">
                <MessageSquare className="w-5 h-5 text-gray-500" />
                <CardTitle className="text-lg">Текст транскрипции</CardTitle>
              </div>
              <Button 
                id="button-copy-transcription"
                variant="ghost" 
                size="icon"
                onClick={() => navigator.clipboard.writeText(transcriptionText)}
                title="Копировать текст"
                className="h-8 w-8 bg-gray-100 hover:bg-gray-200 border-gray-200"
              >
                <Copy className="w-4 h-4 text-gray-600" />
              </Button>
            </CardHeader>
            <CardContent>
              <Textarea
                id="input-transcription"
                value={transcriptionText}
                onChange={(e) => setTranscriptionText(e.target.value)}
                rows={12}
                className="w-full"
                placeholder="Введите или вставьте текст транскрипции здесь..."
              />
            </CardContent>
          </Card>

          <Card id="result-card">
            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-4">
              <div className="flex items-center gap-2">
                <Bot className="w-5 h-5 text-gray-500" />
                <CardTitle className="text-lg">Результат</CardTitle>
              </div>
              <Button 
                id="button-copy-result"
                variant="ghost" 
                size="icon"
                onClick={() => navigator.clipboard.writeText(resultText)}
                title="Копировать результат"
                className="h-8 w-8 bg-gray-100 hover:bg-gray-200 border-gray-200"
              >
                <Copy className="w-4 h-4 text-gray-600" />
              </Button>
            </CardHeader>
            <CardContent>
              <Textarea
                id="output-result"
                value={resultText}
                readOnly
                rows={12}
                className="w-full bg-gray-50"
                placeholder="Здесь появится результат обработки..."
              />
            </CardContent>
          </Card>
        </div>

        {/* Правая колонка */}
        <div className="lg:col-span-2 space-y-6">
          <Card id="settings-card">
            <CardHeader className="flex flex-row items-center justify-between pb-2">
              <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between w-full gap-4">
                <div className="flex items-center gap-2">
                  <Settings className="w-5 h-5 text-gray-500" />
                  <CardTitle className="text-lg">Настройки</CardTitle>
                </div>
                <div className="flex bg-gray-100 rounded-lg p-1">
                  <button
                    id="button-model-gpt4"
                    className={`px-3 py-1 rounded-md text-sm ${
                      selectedModel === 'gpt4' 
                        ? 'bg-white shadow-sm' 
                        : 'text-gray-600 hover:text-gray-900'
                    }`}
                    onClick={() => setSelectedModel('gpt4')}
                  >
                    gpt-4
                  </button>
                  <button
                    id="button-model-llama"
                    className={`px-3 py-1 rounded-md text-sm ${
                      selectedModel === 'llama' 
                        ? 'bg-white shadow-sm' 
                        : 'text-gray-600 hover:text-gray-900'
                    }`}
                    onClick={() => setSelectedModel('llama')}
                  >
                    Llama
                  </button>
                </div>
              </div>
            </CardHeader>
            <CardContent className="space-y-4">
              <div>
                <div className="flex items-center gap-2 mb-1">
                  <label className="text-sm font-medium text-gray-700">
                    Предустановленный промпт
                  </label>
                  <Button 
                    id="help-prompt-preset"
                    variant="ghost" 
                    size="sm" 
                    className="p-0 h-auto"
                    title="Выберите тип протокола из предустановленных шаблонов"
                  >
                    <HelpCircle className="w-4 h-4 bg-gray-500 text-white rounded-full" />
                  </Button>
                </div>
                <Select 
                  id="select-prompt-preset"
                  value={selectedPromptId} 
                  onChange={(e) => handlePromptSelect(e.target.value)}
                  className="w-full"
                >
                  {Object.values(predefinedPrompts).map((prompt) => (
                    <option key={prompt.id} value={prompt.id}>
                      {prompt.title}
                    </option>
                  ))}
                </Select>
              </div>

              <div id="system-prompt-container" className="bg-gray-50 rounded-lg p-4">
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Системный промпт
                </label>
                <div id="system-prompt-text" className="text-sm text-gray-600">
                  {systemPrompt}
                </div>
              </div>

              <div>
                <div className="flex items-center gap-2 mb-1">
                  <label className="text-sm font-medium text-gray-700">
                    Дополнительный промпт
                  </label>
                  <Button 
                    id="help-prompt-additional"
                    variant="ghost" 
                    size="sm" 
                    className="p-0 h-auto"
                    title="Добавьте свои требования к формату или содержанию протокола"
                  >
                    <HelpCircle className="w-4 h-4 bg-gray-500 text-white rounded-full" />
                  </Button>
                </div>
                <Textarea
                  id="input-prompt-additional"
                  value={userPrompt}
                  onChange={(e) => setUserPrompt(e.target.value)}
                  className="w-full"
                  placeholder="Введите дополнительные требования к обработке..."
                  rows={3}
                />
              </div>

              <div className="pt-4">
                <Button 
                  id="button-process"
                  onClick={startProcessing} 
                  className="w-full bg-violet-600 hover:bg-violet-700 text-white h-12"
                  disabled={isProcessing}
                >
                  {isProcessing ? 'Обработка...' : 'Обработать текст'}
                </Button>

                {taskStatus && taskStatus !== 'completed' && (
                  <div id="task-status-container" className="mt-4">
                    {taskStatus === 'processing' && (
                      <>
                        <Progress 
                          id="progress-processing"
                          value={progress} 
                          className="h-2 bg-gray-100" 
                          indicatorClassName="bg-violet-600"
                        />
                        <div className="flex justify-between mt-2 text-sm text-gray-600">
                          <span id="progress-text">Прогресс: {progress}%</span>
                        </div>
                      </>
                    )}
                    {taskStatus === 'pending' && (
                      <div id="status-pending" className="text-sm text-gray-600">
                        Задача в очереди на обработку...
                      </div>
                    )}
                    {taskStatus === 'failed' && (
                      <div id="status-failed" className="text-sm text-red-500">
                        Ошибка при обработке задачи
                      </div>
                    )}
                  </div>
                )}
              </div>
            </CardContent>
          </Card>
        </div>
      </div>

      {error && (
        <div id="error-message" className="p-4 bg-red-50 rounded-lg">
          <p className="text-red-500">{error}</p>
        </div>
      )}
    </div>
  );
};

export default MeetingProtocol;
