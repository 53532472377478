import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Upload, Copy, Save, Trash2 } from 'lucide-react';
import axios from 'axios';
import { Button } from './ui/button';
import { Progress } from './ui/progress';
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from './ui/card';
import { Checkbox } from './ui/checkbox';
import { authService } from '../services/authService';

const API_BASE_URL = 'https://devtools.1bitai.ru:9085';
const PUNCTUATION_API_URL = 'https://devtools.1bitai.ru:9080';

const TranscriptionWithoutDiarization = ({ state, setState, onOpenProtocol }) => {
  const [file, setFile] = useState(state.file || null);
  const [transcription, setTranscription] = useState(state.transcription || null);
  const [isProcessing, setIsProcessing] = useState(state.isProcessing || false);
  const [isPunctuationProcessing, setIsPunctuationProcessing] = useState(false);
  const [progress, setProgress] = useState(state.progress || 0);
  const [uploadProgress, setUploadProgress] = useState(state.uploadProgress || 0);
  const [transcribeProgress, setTranscribeProgress] = useState(state.transcribeProgress || 0);
  const [punctuationProgress, setPunctuationProgress] = useState(state.punctuationProgress || 0);
  const [currentBatch, setCurrentBatch] = useState(state.currentBatch || null);
  const [totalBatches, setTotalBatches] = useState(state.totalBatches || null);
  const [totalTranscriptionTime, setTotalTranscriptionTime] = useState(state.totalTranscriptionTime || null);
  const [taskId, setTaskId] = useState(state.taskId || null);
  const [punctuationTaskId, setPunctuationTaskId] = useState(state.punctuationTaskId || null);
  const [error, setError] = useState(state.error || null);
  const [addPunctuation, setAddPunctuation] = useState(state.addPunctuation || false);
  const [splitSentences, setSplitSentences] = useState(state.splitSentences || false);
  const fileInputRef = useRef(null);

  useEffect(() => {
    setState({
      file,
      transcription,
      isProcessing,
      progress,
      uploadProgress,
      transcribeProgress,
      punctuationProgress,
      currentBatch,
      totalBatches,
      totalTranscriptionTime,
      taskId,
      punctuationTaskId,
      error,
      addPunctuation,
      splitSentences
    });
  }, [file, transcription, isProcessing, progress, uploadProgress, transcribeProgress, 
      punctuationProgress, currentBatch, totalBatches, totalTranscriptionTime, taskId, 
      punctuationTaskId, error, addPunctuation, splitSentences, setState]);

  const cleanTimecodes = (text) => {
    if (!text) return '';
    return text.replace(/\n?\[\d{2}:\d{2}:\d{2}\.\d{3} - \d{2}:\d{2}:\d{2}\.\d{3}\]:\s*/g, ' ');
  };

  const handleOpenProtocol = () => {

    const cleanedText = cleanTimecodes(transcription);

    //if (transcription) {
    onOpenProtocol(cleanedText);
    //}
  };

  const checkTaskStatus = useCallback(async () => {
    if (!taskId || !isProcessing) return;

    try {
      const token = authService.getToken();
      const response = await axios.get(`${API_BASE_URL}/get_status?task_id=${taskId}`, {
        headers: { 'token': `${token}` }
      });
      
      const { 
        status, 
        progress, 
        upload_progress, 
        transcribe_progress, 
        current_batch, 
        total_batches, 
        total_transcription_time, 
        error: taskError 
      } = response.data;

      setProgress(progress);
      setUploadProgress(upload_progress);
      setTranscribeProgress(transcribe_progress);
      setCurrentBatch(current_batch);
      setTotalBatches(total_batches);

      if (status === 'READY') {
        setIsProcessing(false);
        setTotalTranscriptionTime(total_transcription_time);
        await fetchTranscriptionResult();
      } else if (status === 'ERROR') {
        setError('Ошибка обработки: ' + taskError);
        setIsProcessing(false);
      }
    } catch (err) {
      console.error('Error checking task status:', err);
      setError('Ошибка получения статуса: ' + (err.response?.data?.detail || err.message));
      setIsProcessing(false);
    }
  }, [taskId, isProcessing]);

  useEffect(() => {
    let intervalId;
    if (isProcessing && taskId) {
      intervalId = setInterval(checkTaskStatus, 1000);
    }
    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [isProcessing, taskId, checkTaskStatus]);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
    resetState();
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const droppedFile = event.dataTransfer.files[0];
    setFile(droppedFile);
    resetState();
  };

  const resetState = () => {
    setTaskId(null);
    setPunctuationTaskId(null);
    setTranscription(null);
    setError(null);
    setProgress(0);
    setUploadProgress(0);
    setTranscribeProgress(0);
    setPunctuationProgress(0);
    setCurrentBatch(null);
    setTotalBatches(null);
    setTotalTranscriptionTime(null);
  };

  const handleUpload = async () => {
    if (!file) {
      fileInputRef.current.click();
      return;
    }
    setIsProcessing(true);
    resetState();

    const formData = new FormData();
    formData.append('file', file);

    try {
      const token = authService.getToken();
      const response = await axios.post(`${API_BASE_URL}/start_transcribing`, formData, {
        headers: { 
          'Content-Type': 'multipart/form-data',
          'token': `${token}`
        },
      });
      setTaskId(response.data.task_id);
    } catch (err) {
      console.error('Error uploading file:', err);
      setError('Ошибка загрузки файла: ' + (err.response?.data?.detail || err.message));
      setIsProcessing(false);
    }
  };

  const fetchTranscriptionResult = async () => {
    if (!taskId) return;

    try {
      const token = authService.getToken();
      const response = await axios.get(`${API_BASE_URL}/get_file?task_id=${taskId}`, {
        headers: { 'token': `${token}` },
        responseType: 'text'
      });
      setTranscription(response.data);
      
      if (addPunctuation) {
        await processPunctuation(response.data);
      }
    } catch (err) {
      console.error('Error fetching transcription result:', err);
      setError('Ошибка получения результата: ' + (err.response?.data?.detail || err.message));
    }
  };


  const processPunctuation = async (text) => {
    try {
      setIsPunctuationProcessing(true);
      setPunctuationProgress(0);
      const token = authService.getToken();
      const response = await axios.post(`${PUNCTUATION_API_URL}/process-text`, {
        text: text.replace(/\n/g, "\\n"),
        options: {
          punctuation: true,
          spelling: false,
          capitalization: false
        }
      }, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      setPunctuationTaskId(response.data.task_id);
      await waitForPunctuationResult(response.data.task_id);
    } catch (err) {
      console.error('Error processing punctuation:', err);
      setError('Ошибка обработки пунктуации: ' + (err.response?.data?.detail || err.message));
    } finally {
      setIsPunctuationProcessing(false);
    }
  };

  const waitForPunctuationResult = async (punctTaskId) => {
    const startTime = Date.now();
    const timeoutDuration = 3 * 60 * 1000; // 3 minutes

    while (Date.now() - startTime < timeoutDuration) {
      try {
        const token = authService.getToken();
        const response = await axios.get(`${PUNCTUATION_API_URL}/task-status/${punctTaskId}`, {
          headers: { 'Authorization': `Bearer ${token}` }
        });

        if (response.data.status === 'Completed') {
          setPunctuationProgress(100);
          setTranscription(response.data.result.replace(/\\n/g, "\n"));
          return;
        } else if (response.data.status === 'Failed') {
          throw new Error('Ошибка обработки пунктуации');
        }

        // Wait for 1 second before the next request
        await new Promise(resolve => setTimeout(resolve, 1000));
        setPunctuationProgress(prev => Math.min(prev + 1, 99)); // Increment progress
      } catch (err) {
        console.error('Error checking punctuation status:', err);
        throw err;
      }
    }

    throw new Error('Превышено время ожидания обработки пунктуации');
  };

  const handleCopy = () => {
    if (transcription) {
      navigator.clipboard.writeText(transcription);
    }
  };

  const handleSave = () => {
    if (transcription) {
      const element = document.createElement("a");
      const file = new Blob([transcription], {type: 'text/plain'});
      element.href = URL.createObjectURL(file);
      element.download = "transcription.txt";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    }
  };

  const handleDelete = () => {
    setFile(null);
    resetState();
  };

  return (
    <Card className="bg-white">
      <CardHeader>
        <CardTitle className="text-xl font-bold">Транскрипция</CardTitle>
      </CardHeader>
      <CardContent>
        <div 
          className="border-2 border-dashed border-gray-300 rounded-lg p-12 mb-6 cursor-pointer bg-[#e5e7eb]"
          onDragOver={handleDragOver}
          onDrop={handleDrop}
          onClick={() => fileInputRef.current.click()}
        >
          <div className="flex flex-col items-center">
            <Upload className="w-12 h-12 text-gray-400 mb-2" />
            <p className="text-center text-gray-500">
              {file ? file.name : "Перетащите файл сюда или кликните для выбора"}
            </p>
            <input
              type="file"
              onChange={handleFileChange}
              className="hidden"
              ref={fileInputRef}
              accept=".mp3,.wav,.ogg,.flac"
            />
          </div>
        </div>

        <div className="mb-4 space-y-2">
          <div className="flex items-center space-x-2">
            <Checkbox 
              id="add-punctuation"
              checked={addPunctuation}
              onChange={(e) => setAddPunctuation(e.target.checked)}
            />
            <label htmlFor="add-punctuation" className="text-sm text-gray-700">Обработка пунктуации и предложений</label>
          </div>
          <div className="flex items-center space-x-2">
            <Checkbox 
              id="split-sentences"
              checked={splitSentences}
              onChange={(e) => setSplitSentences(e.target.checked)}
            />
            <label htmlFor="split-sentences" className="text-sm text-gray-700">(...)</label>
          </div>
        </div>

        <Button onClick={handleUpload} className="w-full bg-[#111827] hover:bg-[#374151] text-white" disabled={isProcessing}>
          <Upload className="w-4 h-4 mr-2" />
          {isProcessing ? 'Обработка...' : 'Загрузить и начать транскрипцию'}
        </Button>

        {isProcessing && (
          <div className="mt-6">
            <Progress value={transcribeProgress} className="w-full bg-gray-200" indicatorClassName="bg-gray-500" />
            <div className="flex justify-between mt-2 text-sm text-gray-600">
              <span>Транскрибация: {transcribeProgress}%</span>
              <span>
                batches {currentBatch}/{totalBatches}
                {totalTranscriptionTime && ` Общее время транскрибации: ${totalTranscriptionTime.toFixed(2)}s`}
              </span>
            </div>
          </div>
        )}

        {isPunctuationProcessing && (
          <div className="mt-6">
            <Progress value={punctuationProgress} className="w-full bg-gray-200" indicatorClassName="bg-gray-500" />
            <div className="flex justify-between mt-2 text-sm text-gray-600">
              <span>Добавление пунктуации: {punctuationProgress}%</span>
            </div>
          </div>
        )}

        {addPunctuation && punctuationProgress > 0 && (
          <div className="mt-6">
            <Progress value={punctuationProgress} className="w-full bg-gray-200" indicatorClassName="bg-gray-500" />
            <div className="flex justify-between mt-2 text-sm text-gray-600">
              <span>Добавление пунктуации: {punctuationProgress}%</span>
            </div>
          </div>
        )}

        {error && (
          <div className="mt-6 text-center text-red-500">
            <p>{error}</p>
          </div>
        )}

        {transcription && (
          <div className="mt-6 bg-gray-100 p-4 rounded-lg">
            <h3 className="font-bold mb-2">Результат транскрипции:</h3>
            <p className="whitespace-pre-wrap">{transcription}</p>
          </div>
        )}
      </CardContent>

      {transcription && (
        <CardFooter className="flex justify-between gap-4">
          <Button onClick={handleCopy} variant="outline">
            <Copy className="w-4 h-4 mr-2" />
            Копировать
          </Button>
          <Button onClick={handleSave} variant="outline">
            <Save className="w-4 h-4 mr-2" />
            Сохранить
          </Button>
          <Button onClick={handleDelete} variant="destructive">
            <Trash2 className="w-4 h-4 mr-2" />
            Удалить
          </Button>
          <Button 
            onClick={handleOpenProtocol}
            className="bg-[#111827] hover:bg-[#374151] text-white"
          >
            Протокол
          </Button>
        </CardFooter>
      )}

    </Card>
  );
};

export default TranscriptionWithoutDiarization;
