import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Upload, Copy, Download, FileText, Trash2, HelpCircle, Users} from 'lucide-react';
import axios from 'axios';
import { Button } from './ui/button';
import { Progress } from './ui/progress';
import { Checkbox } from './ui/checkbox';
import { authService } from '../services/authService';
import EditSpeakersModal from './EditSpeakersModal';

const API_BASE_URL = process.env.REACT_APP_ASR_API_URL;
const PUNCTUATION_API_URL = process.env.REACT_APP_PUNCTUATION_API_URL;

const TranscriptionWithoutDiarization = ({ state, setState, onOpenProtocol }) => {
  const [file, setFile] = useState(state.file || null);
  const [rawTranscription, setRawTranscription] = useState(state.rawTranscription || null);
  const [parsedTranscription, setParsedTranscription] = useState(state.parsedTranscription || []);
  const [isProcessing, setIsProcessing] = useState(state.isProcessing || false);
  const [isPunctuationProcessing, setIsPunctuationProcessing] = useState(false);
  const [progress, setProgress] = useState(state.progress || 0);
  const [uploadProgress, setUploadProgress] = useState(state.uploadProgress || 0);
  const [transcribeProgress, setTranscribeProgress] = useState(state.transcribeProgress || 0);
  const [punctuationProgress, setPunctuationProgress] = useState(state.punctuationProgress || 0);
  const [currentBatch, setCurrentBatch] = useState(state.currentBatch || null);
  const [totalBatches, setTotalBatches] = useState(state.totalBatches || null);
  const [totalTranscriptionTime, setTotalTranscriptionTime] = useState(state.totalTranscriptionTime || null);
  const [taskId, setTaskId] = useState(state.taskId || null);
  const [punctuationTaskId, setPunctuationTaskId] = useState(state.punctuationTaskId || null);
  const [error, setError] = useState(state.error || null);
  const [addPunctuation, setAddPunctuation] = useState(state.addPunctuation || false);
  const [addDiarization, setAddDiarization] = useState(state.addDiarization || false);
  const [deleteTimecodes, setDeleteTimecodes] = useState(state.deleteTimecodes || false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [speakers, setSpeakers] = useState([]);
  const [currentProcessType, setCurrentProcessType] = useState('транскрипция');

  const fileInputRef = useRef(null);

  useEffect(() => {
    setState({
      file,
      rawTranscription,
      parsedTranscription,
      isProcessing,
      progress,
      uploadProgress,
      transcribeProgress,
      punctuationProgress,
      currentBatch,
      totalBatches,
      totalTranscriptionTime,
      taskId,
      punctuationTaskId,
      error,
      addPunctuation,
      addDiarization,
      deleteTimecodes,
      speakers
    });
  }, [file, rawTranscription, parsedTranscription, isProcessing, progress, uploadProgress,
      transcribeProgress, punctuationProgress, currentBatch, totalBatches,
      totalTranscriptionTime, taskId, punctuationTaskId, error, addPunctuation,
      addDiarization, deleteTimecodes, speakers, setState]);

  const formatTimecode = (timecodeStr) => {
    const matches = timecodeStr.match(/\[(\d{2}):(\d{2}):(\d{2}\.\d{3}) - (\d{2}):(\d{2}):(\d{2}\.\d{3})\]/);
    if (!matches) return timecodeStr;

    const [_, h1, m1, s1, h2, m2, s2] = matches;
    const startSeconds = parseFloat(h1) * 3600 + parseFloat(m1) * 60 + parseFloat(s1);
    const endSeconds = parseFloat(h2) * 3600 + parseFloat(m2) * 60 + parseFloat(s2);

    return `[${startSeconds.toFixed(2)}s - ${endSeconds.toFixed(2)}s]`;
  };

const parseTranscription = (text) => {
  if (!text) return [];

  const fragments = text.split('\n').filter(line => line.trim());
  return fragments.map(fragment => {
    const timecodeMatch = fragment.match(/\[\d{2}:\d{2}:\d{2}\.\d{3} - \d{2}:\d{2}:\d{2}\.\d{3}\]/);
    // Изменение: заменяем SPEAKER на УЧАСТНИК
    const speakerMatch = fragment.match(/\[SPEAKER_\d+\]/);

    // Если включена опция удаления таймкодов, не добавляем их
    const timecode = deleteTimecodes ? null : (timecodeMatch ? formatTimecode(timecodeMatch[0]) : null);

    let speaker = null;
    if (speakerMatch) {
      // Изменение: преобразование SPEAKER_X в УЧАСТНИК_Y, где Y = X + 1
      const speakerNumber = parseInt(speakerMatch[0].match(/\d+/)[0]);
      speaker = `[УЧАСТНИК_${speakerNumber + 1}]`;
    }

    const text = fragment
      .replace(timecodeMatch?.[0] || '', '')
      .replace(speakerMatch?.[0] || '', '')
      .replace(':', '')
      .trim();

    return { timecode, speaker, text };
  });
};


const formatTranscriptionText = (fragments) => {
  if (!fragments?.length) return '';

  return fragments.map(fragment => {
    const { timecode, speaker, text } = fragment;

    // Если нет таймкода (или они отключены) - возвращаем просто текст
    if ((!timecode || deleteTimecodes) && (!speaker || !addDiarization)) return text;

    const metadata = addDiarization && speaker
      ? `<span class="font-bold text-violet-600">${speaker}</span> ${!deleteTimecodes && timecode ? `<span class="text-gray-400">${timecode}</span>` : ''}:`
      : !deleteTimecodes && timecode ? `<span class="text-gray-400">${timecode}</span>:` : '';

    return `<div class="mt-px"><span class="text-sm">${metadata}</span>\n${text}</div>`;
  }).join('');
};

  const extractSpeakers = (fragments) => {
    if (!fragments?.length) return [];
    return [...new Set(fragments
      .map(fragment => fragment.speaker)
      .filter(speaker => speaker)
    )];
  };

  const handleEditSpeakerNames = () => {
    const extractedSpeakers = extractSpeakers(parsedTranscription);
    setSpeakers(extractedSpeakers);
    setIsEditModalOpen(true);
  };

  const handleSaveSpeakerNames = (editedSpeakers) => {
    if (parsedTranscription.length) {
      const updatedTranscription = parsedTranscription.map(fragment => ({
        ...fragment,
        speaker: fragment.speaker ? editedSpeakers[fragment.speaker] || fragment.speaker : null
      }));
      setParsedTranscription(updatedTranscription);
    }
  };

  const cleanTimecodes = (fragments) => {
    if (!fragments?.length) return '';
    return fragments.map(fragment => fragment.text).join('\n');
  };

  const handleOpenProtocol = () => {
    const cleanedText = cleanTimecodes(parsedTranscription);
    onOpenProtocol(cleanedText);
  };

  const checkTaskStatus = useCallback(async () => {
    if (!taskId || !isProcessing) return;

    try {
      const token = authService.getToken();
      const response = await axios.get(`${API_BASE_URL}/get_status?task_id=${taskId}`, {
        headers: { 'token': `${token}` }
      });

      const {
        status,
        progress,
        upload_progress,
        transcribe_progress,
        current_batch,
        total_batches,
        total_transcription_time,
        error: taskError
      } = response.data;

      setProgress(progress);
      setUploadProgress(upload_progress);
      setTranscribeProgress(transcribe_progress);
      setCurrentBatch(current_batch);
      setTotalBatches(total_batches);
      setCurrentProcessType('транскрипция');

      if (status === 'READY') {
        setIsProcessing(false);
        setTotalTranscriptionTime(total_transcription_time);
        await fetchTranscriptionResult();
      } else if (status === 'ERROR') {
        setError('Ошибка обработки: ' + taskError);
        setIsProcessing(false);
      }
    } catch (err) {
      console.error('Error checking task status:', err);
      setError('Ошибка получения статуса: ' + (err.response?.data?.detail || err.message));
      setIsProcessing(false);
    }
  }, [taskId, isProcessing]);

  useEffect(() => {
    let intervalId;
    if (isProcessing && taskId) {
      intervalId = setInterval(checkTaskStatus, 1000);
    }
    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [isProcessing, taskId, checkTaskStatus]);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
    resetState();
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const droppedFile = event.dataTransfer.files[0];
    setFile(droppedFile);
    resetState();
  };

  const resetState = () => {
    setTaskId(null);
    setPunctuationTaskId(null);
    setRawTranscription(null);
    setParsedTranscription([]);
    setError(null);
    setProgress(0);
    setUploadProgress(0);
    setTranscribeProgress(0);
    setPunctuationProgress(0);
    setCurrentBatch(null);
    setTotalBatches(null);
    setTotalTranscriptionTime(null);
    setCurrentProcessType('транскрипция');
  };

  const handleUpload = async () => {
    if (!file) {
      fileInputRef.current.click();
      return;
    }

    setIsProcessing(true);
    resetState();

    const formData = new FormData();
    formData.append('file', file);

    try {
      const token = authService.getToken();
      const url = new URL(`${API_BASE_URL}/start_transcribing`);
      url.searchParams.append('diarize', addDiarization);
      url.searchParams.append('remove_timestamps', deleteTimecodes);

      const response = await axios.post(url.toString(), formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'token': `${token}`
        },
      });

      setTaskId(response.data.task_id);
    } catch (err) {
      console.error('Error uploading file:', err);
      setError('Ошибка загрузки файла: ' + (err.response?.data?.detail || err.message));
      setIsProcessing(false);
    }
  };

const fetchTranscriptionResult = async () => {
  if (!taskId) return;

  try {
    const token = authService.getToken();
    const response = await axios.get(`${API_BASE_URL}/get_file?task_id=${taskId}`, {
      headers: { 'token': `${token}` },
      responseType: 'text'
    });

    setRawTranscription(response.data);
    setParsedTranscription(parseTranscription(response.data));

    if (addPunctuation) {
      setIsPunctuationProcessing(true);
      setCurrentProcessType('пунктуация');
      setPunctuationProgress(0);
      await processPunctuation(response.data);
      setIsPunctuationProcessing(false);
      setCurrentProcessType('транскрипция');
    }

  } catch (err) {
    console.error('Error fetching transcription result:', err);
    setError('Ошибка получения результата: ' + (err.response?.data?.detail || err.message));
  }
};

const processPunctuation = async (text) => {
  try {
    const token = authService.getToken();
    const fragments = parseTranscription(text);
    const processedFragments = [...fragments];

    for (let i = 0; i < fragments.length; i++) {
      const fragment = fragments[i];
      if (!fragment.text.trim()) continue;

      try {
        const response = await axios.post(
          `${PUNCTUATION_API_URL}/process-text`,
          {
            text: fragment.text,
            options: {
              punctuation: true,
              spelling: false,
              capitalization: false
            }
          },
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          }
        );

        const processedText = await waitForPunctuationResult(response.data.task_id);

        // Обновляем только text, сохраняя метаданные
        processedFragments[i] = {
          ...fragment,
          text: processedText.trim()
        };

        setPunctuationProgress(Math.round((i + 1) / fragments.length * 100));
      } catch (err) {
        console.error('Error processing fragment:', err);
        continue;
      }
    }

    setParsedTranscription(processedFragments);
  } catch (err) {
    console.error('Error in punctuation processing:', err);
    setError('Ошибка обработки пунктуации: ' + (err.response?.data?.detail || err.message));
  }
};

const waitForPunctuationResult = async (taskId) => {
  const startTime = Date.now();
  const timeoutDuration = 3 * 60 * 1000; // 3 minutes timeout

  while (Date.now() - startTime < timeoutDuration) {
    const token = authService.getToken();
    const response = await axios.get(
      `${PUNCTUATION_API_URL}/task-status/${taskId}`,
      {
        headers: { 'Authorization': `Bearer ${token}` }
      }
    );

    if (response.data.status === 'Completed') {
      return response.data.result;
    }

    if (response.data.status === 'Failed') {
      throw new Error('Ошибка обработки пунктуации');
    }

    await new Promise(resolve => setTimeout(resolve, 1000));
  }

  throw new Error('Превышено время ожидания обработки пунктуации');
};


  const handleCopy = () => {
    if (rawTranscription) {
      navigator.clipboard.writeText(rawTranscription);
    }
  };

  const handleSave = () => {
    if (rawTranscription) {
      const element = document.createElement("a");
      const file = new Blob([rawTranscription], {type: 'text/plain'});
      element.href = URL.createObjectURL(file);
      element.download = "transcription.txt";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    }
  };

const handleDelete = () => {
    setFile(null);
    resetState();
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  return (
    <div className="space-y-4 font-montserrat">
      <div>
        <h1 className="text-2xl font-semibold mb-2">Транскрипция</h1>
        <p className="text-gray-600">Загрузите видео или аудио и получите структурированный текст</p>
      </div>

      <div className="flex flex-col md:flex-row md:justify-between items-start mb-2 gap-4 md:gap-2">
        <div className="w-full md:w-auto space-y-2">
          <div className="flex items-center space-x-2">
            <Checkbox
              id="checkbox-punctuation"
              checked={addPunctuation}
              onChange={(e) => setAddPunctuation(e.target.checked)}
            />
            <label htmlFor="checkbox-punctuation" className="text-sm">
              Обработка пунктуации и предложений
            </label>
            <Button
              id="help-punctuation"
              variant="ghost"
              size="sm"
              className="p-0 h-auto border-0 outline-0 ring-0"
              title="Автоматическая расстановка знаков препинания и разделение текста на предложения"
            >
              <HelpCircle className="w-4 h-4 bg-gray-500 text-white rounded-full outline-none border-none ring-none" />
            </Button>
          </div>

          <div className="flex items-center space-x-2">
            <Checkbox
              id="checkbox-diarization"
              checked={addDiarization}
              onChange={(e) => setAddDiarization(e.target.checked)}
            />
            <label htmlFor="checkbox-diarization" className="text-sm">
              Разделение по участникам
            </label>
            <Button
              id="help-diarization"
              variant="ghost"
              size="sm"
              className="p-0 h-auto"
              title="Разделение транскрипции по участникам с указанием их меток"
            >
              <HelpCircle className="w-4 h-4 bg-gray-500 text-white rounded-full" />
            </Button>
          </div>

          <div className="flex items-center space-x-2">
            <Checkbox
              id="checkbox-timecodes"
              checked={deleteTimecodes}
              onChange={(e) => setDeleteTimecodes(e.target.checked)}
            />
            <label htmlFor="checkbox-timecodes" className="text-sm">
              Убрать метки времени
            </label>
            <Button
              id="help-timecodes"
              variant="ghost"
              size="sm"
              className="p-0 h-auto"
              title="Убирает разметку времени из текста транскрипции"
            >
              <HelpCircle className="w-4 h-4 bg-gray-500 text-white rounded-full" />
            </Button>
          </div>
        </div>

        <div className="flex gap-2 w-full md:w-auto justify-start md:justify-end mt-1">
          {addDiarization && parsedTranscription.length > 0 && (
            <Button
              id="button-edit-speakers"
              onClick={handleEditSpeakerNames}
              variant="outline"
              size="icon"
              className="h-8 w-8 bg-gray-100 hover:bg-gray-200 border-gray-200"
              title="Редактировать имена участников"
            >
              <Users className="w-4 h-4 text-gray-600" />
            </Button>
          )}

          <Button
            id="button-copy"
            onClick={handleCopy}
            variant="outline"
            size="icon"
            className="h-8 w-8 bg-gray-100 hover:bg-gray-200 border-gray-200"
            title="Копировать"
          >
            <Copy className="w-4 h-4 text-gray-600" />
          </Button>

          <Button
            id="button-save"
            onClick={handleSave}
            variant="outline"
            size="icon"
            className="h-8 w-8 bg-gray-100 hover:bg-gray-200 border-gray-200"
            title="Скачать"
          >
            <Download className="w-4 h-4 text-gray-600" />
          </Button>

          <Button
            id="button-delete"
            onClick={handleDelete}
            variant="outline"
            size="icon"
            className="h-8 w-8 bg-gray-100 hover:bg-gray-200 border-gray-200"
            title="Удалить"
          >
            <Trash2 className="w-4 h-4 text-gray-600" />
          </Button>

          <Button
            id="button-protocol"
            onClick={handleOpenProtocol}
            variant="outline"
            className="h-8 bg-gray-100 hover:bg-gray-200 border-gray-200 px-3"
          >
            Протокол
          </Button>
        </div>
      </div>

      <div
        id="dropzone"
        className="border-2 border-dashed border-gray-300 rounded-lg p-6 cursor-pointer hover:bg-gray-50 transition-colors"
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        onClick={() => fileInputRef.current.click()}
      >
        <div className="flex flex-col items-center">
          <Upload className="w-12 h-12 text-gray-400 mb-2" />
          <p className="text-center text-gray-500">
            {file ? file.name : "Перетащите видео или аудио сюда или кликните для выбора"}
          </p>
          <input
            id="file-input"
            type="file"
            onChange={handleFileChange}
            className="hidden"
            ref={fileInputRef}
            accept="audio/*,video/*"
          />
        </div>
      </div>

      <Button
        id="button-start"
        onClick={handleUpload}
        className="w-full bg-violet-600 hover:bg-violet-700 text-white h-12 font-montserrat"
        disabled={isProcessing || isPunctuationProcessing}
      >
        {isProcessing || isPunctuationProcessing ? 'Обработка...' : 'Начать транскрипцию'}
      </Button>

      {(isProcessing || isPunctuationProcessing) && (
        <div className="mb-4">
          <p id="process-type" className="text-sm text-gray-500 mb-1">
            {currentProcessType}
          </p>
          <Progress
            id="progress-bar"
            value={isPunctuationProcessing ? punctuationProgress : transcribeProgress}
            className="h-2 bg-gray-100"
            indicatorClassName="bg-violet-600"
          />
        </div>
      )}

      {error && (
        <div id="error-message" className="text-red-600 text-sm mb-4">
          {error}
        </div>
      )}

      {parsedTranscription.length > 0 && (
        <div id="transcription-result" className="bg-gray-50 p-4 rounded-lg max-h-screen overflow-y-auto overflow-x-hidden">
          <h3 className="font-medium mb-2">Результат транскрипции:</h3>
          <div className="whitespace-pre-wrap">
            <div dangerouslySetInnerHTML={{
              __html: formatTranscriptionText(parsedTranscription)
            }} />
          </div>
        </div>
      )}

      <EditSpeakersModal
        isOpen={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        speakers={speakers}
        onSave={handleSaveSpeakerNames}
      />
    </div>
  );
};

export default TranscriptionWithoutDiarization;