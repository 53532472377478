import React, { useState } from 'react';
import { Card, CardHeader, CardTitle, CardContent } from './ui/card';
import { Input } from './ui/input';
import { Button } from './ui/button';
import { authService } from '../services/authService';

const AuthPage = ({ onAuth }) => {
  const AUTH_MODES = {
    REQUEST_TOKEN: 'request-token',
    ENTER_TOKEN: 'enter-token'
  };

  const [email, setEmail] = useState('');
  const [token, setToken] = useState('');
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [authMode, setAuthMode] = useState(AUTH_MODES.ENTER_TOKEN);

  const handleRequestToken = async () => {
    setError('');
    setMessage('');
    try {
      const response = await authService.requestToken(email);
      setMessage(response.message);
      setAuthMode(AUTH_MODES.ENTER_TOKEN);
    } catch (err) {
      setError(err.message);
    }
  };

  const handleSubmitToken = async () => {
    setError('');
    setMessage('');
    try {
      await onAuth(token);
    } catch (err) {
      setError(err.message || "Ошибка при проверке токена");
    }
  };

  const cancelRequestToken = () => {
    setError('');
    setMessage('');
    setAuthMode(AUTH_MODES.ENTER_TOKEN);
  };

  const renderRequestTokenView = () => (
    <CardContent className="space-y-6">
      <div>
        <Input
          id="input-email"
          type="email"
          placeholder="Введите ваш email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className={error ? 'border-red-500' : ''}
        />
        {error && <p id="error-email" className="text-red-500 mt-2 text-sm">{error}</p>}
      </div>
      <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4">
        <Button
          id="button-request-token"
          className="w-full sm:flex-1 bg-[#782FFF] hover:bg-[#8A47FF] text-white"
          onClick={handleRequestToken}
        >
          Запросить токен
        </Button>
        <Button
          id="button-cancel-request"
          variant="outline"
          className="w-full sm:flex-1"
          onClick={cancelRequestToken}
        >
          Отмена
        </Button>
      </div>
    </CardContent>
  );

  const renderEnterTokenView = () => (
    <CardContent className="space-y-6">
      <div>
        <Input
          id="input-token"
          type="text"
          placeholder="Введите токен доступа"
          value={token}
          onChange={(e) => setToken(e.target.value)}
          className={error ? 'border-red-500' : ''}
        />
        {error && <p id="error-token" className="text-red-500 mt-2 text-sm">{error}</p>}
        {message && <p id="message-token" className="text-green-500 mt-2 text-sm">{message}</p>}
      </div>
      <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4">
        <Button
          id="button-submit-token"
          className="w-full sm:flex-1 bg-[#782FFF] hover:bg-[#8A47FF] text-white"
          onClick={handleSubmitToken}
        >
          Войти
        </Button>
        <Button
          id="button-switch-to-request"
          variant="outline"
          className="w-full sm:flex-1"
          onClick={() => setAuthMode(AUTH_MODES.REQUEST_TOKEN)}
        >
          Запросить токен
        </Button>
      </div>
    </CardContent>
  );

  return (
    <div id="auth-page" className="flex items-center justify-center min-h-screen bg-gray-100 font-montserrat px-6 py-8 sm:p-4">
      <Card id="auth-card" className="w-full max-w-xl mx-6 sm:mx-auto">
        <CardHeader className="flex flex-col sm:flex-row justify-between items-center gap-4 sm:gap-0 pb-6">
          <CardTitle id="auth-title" className="text-2xl font-bold order-2 sm:order-1">Вход</CardTitle>
          <img id="auth-logo" src="/logo_firstbit_newton.png" alt="firstBit Logo" className="h-8 w-auto order-1 sm:order-2" />
        </CardHeader>

        {authMode === AUTH_MODES.REQUEST_TOKEN && renderRequestTokenView()}
        {authMode === AUTH_MODES.ENTER_TOKEN && renderEnterTokenView()}
      </Card>
    </div>
  );
};

export default AuthPage;