import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button } from './ui/button';
import { Progress } from './ui/progress';
import { Card, CardContent, CardHeader, CardTitle } from './ui/card';
import { Input } from './ui/input';
import { Select } from './ui/select';
import { Textarea } from './ui/Textarea';
import { authService } from '../services/authService';

import { Copy, Bot, Settings, MessageSquare } from 'lucide-react'; // Добавляем иконки

const API_BASE_URL = 'https://devtools.1bitai.ru:9045/api/v1';

const MeetingProtocol = ({ state, setState }) => {
  const [transcriptionText, setTranscriptionText] = useState(state.transcriptionText || '');
  const [userPrompt, setUserPrompt] = useState(state.userPrompt || '');
  const [systemPrompt, setSystemPrompt] = useState(state.systemPrompt || '');
  const [selectedPromptId, setSelectedPromptId] = useState(state.selectedPromptId || 'meeting_detailed');
  const [resultText, setResultText] = useState(state.resultText || '');
  const [isProcessing, setIsProcessing] = useState(state.isProcessing || false);
  const [progress, setProgress] = useState(state.progress || 0);
  const [taskId, setTaskId] = useState(state.taskId || null);
  const [error, setError] = useState(state.error || null);
  const [predefinedPrompts, setPredefinedPrompts] = useState({});
  const [selectedModel, setSelectedModel] = useState(state.selectedModel || 'gpt4');
  const [taskStatus, setTaskStatus] = useState(state.taskStatus || null);

  useEffect(() => {
    fetchPredefinedPrompts();
  }, []);

  useEffect(() => {
    setState({
      transcriptionText,
      userPrompt,
      systemPrompt,
      selectedPromptId,
      resultText,
      isProcessing,
      progress,
      taskId,
      error,
      selectedModel,
      taskStatus
    });
  }, [transcriptionText, userPrompt, systemPrompt, selectedPromptId, resultText, isProcessing, 
      progress, taskId, error, selectedModel, taskStatus, setState]);

  const fetchPredefinedPrompts = async () => {
    try {
      const token = authService.getToken();
      const response = await axios.get(`${API_BASE_URL}/prompts`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      
      setPredefinedPrompts(response.data);
      
      // Устанавливаем значения для meeting_detailed по умолчанию
      if (response.data.meeting_detailed) {
        setSelectedPromptId('meeting_detailed');
        setSystemPrompt(response.data.meeting_detailed.system_prompt);
      }
    } catch (err) {
      console.error('Error fetching predefined prompts:', err);
      handleApiError(err, 'при загрузке промптов');
    }
  };

  const handlePromptSelect = (value) => {
    setSelectedPromptId(value);
    const selectedPrompt = predefinedPrompts[value];
    if (selectedPrompt) {
      setSystemPrompt(selectedPrompt.system_prompt);
    }
  };

  const startProcessing = async () => {
    if (!transcriptionText || !selectedPromptId) {
      setError('Необходимо заполнить текст и выбрать промпт');
      return;
    }

    setIsProcessing(true);
    setProgress(0);
    setError(null);
    setResultText('');
    setTaskId(null);
    setTaskStatus(null);

    try {
      const token = authService.getToken();
      const response = await axios.post(`${API_BASE_URL}/tasks`, {
        text: transcriptionText,
        prompt_id: selectedPromptId,
        model: selectedModel,
        user_prompt: userPrompt
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      setTaskId(response.data.task_id);
      await checkProgress(response.data.task_id);
    } catch (err) {
      console.error('Error starting processing:', err);
      handleApiError(err, 'при запуске обработки');
      setIsProcessing(false);
    }
  };

  const checkProgress = async (taskId) => {
    const pollInterval = setInterval(async () => {
      try {
        if (!taskId) {
          clearInterval(pollInterval);
          return;
        }

        const token = authService.getToken();
        const response = await axios.get(`${API_BASE_URL}/tasks/${taskId}/status`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        const { status, progress } = response.data;
        setTaskStatus(status);
        setProgress(progress);

        if (status === 'completed') {
          clearInterval(pollInterval);
          await fetchResult(taskId);
        } else if (status === 'failed') {
          clearInterval(pollInterval);
          setError('Произошла ошибка при обработке задачи');
          setIsProcessing(false);
        }
      } catch (err) {
        console.error('Error checking progress:', err);
        clearInterval(pollInterval);
        handleApiError(err, 'при проверке прогресса');
        setIsProcessing(false);
      }
    }, 5000);

    return () => clearInterval(pollInterval);
  };

  const fetchResult = async (taskId) => {
    try {
      const token = authService.getToken();
      const response = await axios.get(`${API_BASE_URL}/tasks/${taskId}/result`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      setResultText(response.data.summary);
      setProgress(100);
    } catch (err) {
      console.error('Error fetching result:', err);
      handleApiError(err, 'при получении результата');
    } finally {
      setIsProcessing(false);
    }
  };

  const handleApiError = (err, context) => {
    if (err.response?.status === 401) {
      setError('Ошибка авторизации ' + context);
    } else if (err.response?.status === 403) {
      setError('Токен истек');
    } else if (err.response?.status === 404) {
      setError('Задача или промпт не найдены');
    } else if (err.response?.status === 400) {
      setError('Неверные параметры запроса');
    } else if (err.response?.status === 500) {
      setError('Внутренняя ошибка сервера');
    } else {
      setError('Ошибка ' + context + ': ' + (err.response?.data?.message || err.message));
    }
  };

  return (
    <div className="grid grid-cols-1 lg:grid-cols-5 gap-6">
      {/* Левая колонка (60%) */}
      <div className="lg:col-span-3 space-y-6">
        {/* Карточка входного текста */}
        <Card>
          <CardHeader className="flex flex-row items-center justify-between">
            <div className="flex items-center gap-2">
              <MessageSquare className="w-5 h-5 text-gray-500" />
              <CardTitle className="text-lg">Текст транскрипции</CardTitle>
            </div>
            <Button 
              variant="ghost" 
              size="icon"
              onClick={() => navigator.clipboard.writeText(transcriptionText)}
              title="Копировать текст"
            >
              <Copy className="w-4 h-4" />
            </Button>
          </CardHeader>
          <CardContent>
            <Textarea
              value={transcriptionText}
              onChange={(e) => setTranscriptionText(e.target.value)}
              rows={12}
              className="w-full"
              placeholder="Введите или вставьте текст транскрипции здесь..."
            />
          </CardContent>
        </Card>

        {/* Карточка результата */}
        <Card>
          <CardHeader className="flex flex-row items-center justify-between">
            <div className="flex items-center gap-2">
              <Bot className="w-5 h-5 text-gray-500" />
              <CardTitle className="text-lg">Результат</CardTitle>
            </div>
            <Button 
              variant="ghost" 
              size="icon"
              onClick={() => navigator.clipboard.writeText(resultText)}
              title="Копировать результат"
            >
              <Copy className="w-4 h-4" />
            </Button>
          </CardHeader>
          <CardContent>
            <Textarea
              value={resultText}
              readOnly
              rows={12}
              className="w-full bg-gray-50"
              placeholder="Здесь появится результат обработки..."
            />
          </CardContent>
        </Card>
      </div>

      {/* Правая колонка (40%) */}
      <div className="lg:col-span-2 space-y-6">
        {/* Карточка настроек */}
        <Card>
          <CardHeader className="flex flex-row items-center justify-between pb-2">
            <div className="flex items-center gap-2">
              <Settings className="w-5 h-5 text-gray-500" />
              <CardTitle className="text-lg">Настройки</CardTitle>
            </div>
            {/* Переключатель моделей */}
            <div className="flex bg-gray-100 rounded-lg p-1">
              <button
                className={`px-3 py-1 rounded-md text-sm ${
                  selectedModel === 'gpt4' 
                    ? 'bg-white shadow-sm' 
                    : 'text-gray-600 hover:text-gray-900'
                }`}
                onClick={() => setSelectedModel('gpt4')}
              >
                GPT-4
              </button>
              <button
                className={`px-3 py-1 rounded-md text-sm ${
                  selectedModel === 'llama' 
                    ? 'bg-white shadow-sm' 
                    : 'text-gray-600 hover:text-gray-900'
                }`}
                onClick={() => setSelectedModel('llama')}
              >
                Llama
              </button>
            </div>
          </CardHeader>
          <CardContent className="space-y-4">
            {/* Выбор промпта */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Предустановленный промпт
              </label>
              <Select 
                value={selectedPromptId} 
                onChange={(e) => handlePromptSelect(e.target.value)}
                className="w-full"
              >
                {Object.values(predefinedPrompts).map((prompt) => (
                  <option key={prompt.id} value={prompt.id}>
                    {prompt.title}
                  </option>
                ))}
              </Select>
            </div>

            {/* Системный промпт */}
            <div className="bg-gray-50 rounded-lg p-4">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Системный промпт
              </label>
              <div className="text-sm text-gray-600">
                {systemPrompt}
              </div>
            </div>

            {/* Пользовательский промпт */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Дополните промпт произвольным запросом (опционально)
              </label>
              <Input
                value={userPrompt}
                onChange={(e) => setUserPrompt(e.target.value)}
                className="w-full"
                placeholder="Введите дополнительные требования к обработке..."
              />
            </div>

            {/* Кнопка обработки и прогресс */}
            <div className="pt-4">
              <Button 
                onClick={startProcessing} 
                className="w-full bg-[#111827] hover:bg-[#374151] text-white"
                disabled={isProcessing}
              >
                {isProcessing ? 'Обработка...' : 'Обработать текст'}
              </Button>

              {taskStatus && taskStatus !== 'completed' && (
                <div className="mt-4">
                  {taskStatus === 'processing' && (
                    <>
                      <Progress value={progress} className="w-full bg-gray-200" indicatorClassName="bg-gray-500" />
                      <div className="flex justify-between mt-2 text-sm text-gray-600">
                        <span>Прогресс: {progress}%</span>
                      </div>
                    </>
                  )}
                  {taskStatus === 'pending' && (
                    <div className="text-sm text-gray-600">
                      Задача в очереди на обработку...
                    </div>
                  )}
                  {taskStatus === 'failed' && (
                    <div className="text-sm text-red-500">
                      Ошибка при обработке задачи
                    </div>
                  )}
                </div>
              )}
            </div>
          </CardContent>
        </Card>
      </div>

      {/* Сообщение об ошибке */}
      {error && (
        <div className="lg:col-span-5">
          <div className="text-red-500 p-4 bg-red-50 rounded-lg">
            {error}
          </div>
        </div>
      )}
    </div>
  );
};

export default MeetingProtocol;
